import React from 'react'
import {Link} from 'gatsby'
import PortableText from '../../serializers/portableText'
import styles from './jobs.module.css'
import RightChevron from '../../../images/icons/right-chevron.svg'

const Jobs = ({jobs}) => {
  // console.log({jobs})
  return (
    <>
      <div className={styles.jobsWrapper}>
        {jobs.map(job => (
          <div className={styles.jobWrapper} key={job.id}>
            <h3>{job.title}</h3>
            {job._rawPositionSummary && <PortableText blocks={job._rawPositionSummary} />}
            <Link to={`/careers-es/${job.slug.current}`} className={styles.moreinfoBtn}><span>Más detalles <RightChevron /></span></Link>
          </div>
        ))}
      </div>

    </>

  )
}
export default Jobs
